.product-form {
  &__field {
    margin-top: 1em;
  }

  &__dropzone {
    border: 1px dashed #b0b0b0;
    border-radius: 5px;
    background: white;
    min-height: 40px;
  }

  &__dropzonetext {
    color: #001e33;
  }

  &__previewimage {
    max-width: 100%;
    height: auto;
    border: 1px solid #dfdfdf;
  }


  &__label {
    color: #000;

    &.disabled {
      opacity: 0.4
    }
  }

  &__disabled-label {
    opacity: 0.4
  }
}