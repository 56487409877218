.ui.table tr td.selectable {
  * {
    display: block;
    padding: 8px;
  }

  *:hover {
    cursor: pointer;
    background-color: rgba(34, 36, 38, .1);
  }
}