.flash-message-wrapper {
  &--container {
    position: fixed;
    top: 1em;
    right: 1em;
    width: 20em;
  }

  &--dismiss-all-container {
    display: flex;
    justify-content: flex-end;
  }
}