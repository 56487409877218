.column.narrow {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ui.button.icon-bttn {
  height: 40px;
}

.ui.button.icon-bttn>svg {
  height: 24px;
  vertical-align: middle;
}

.nav-bar-loader {
  display: inline-block;
  margin-bottom: 0.25em;
  margin-right: 0.25em;
}

.nav-bar-links>.ui.button {
  margin-bottom: 0.25em;
}

.nav-bar-links>.ui.dropdown {
  margin-bottom: 0.25em;
  margin-right: 0.25em;
}