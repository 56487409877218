.tax-table-wrapper {
  height: 70vh;
  width: 100%;
  overflow-x: auto;
  margin-right: 8px;
}

.ReactVirtualized__Table {
  border-left: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  font-size: 12px;
}

.ReactVirtualized__Table__headerColumn {
  height: 100%;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.ReactVirtualized__Table__headerRow {
  display: flex;
  color: #666;
  background: #F7F7F7;
  border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
}

.ReactVirtualized__Table__row {
  display: flex;
  border-bottom: 1px solid #E6E6E6;
  color: #001E33;
  align-items: center;
}

.ReactVirtualized__Table__rowColumn {
  padding-left: 8px;
}

.ReactVirtualized__Table__sortableHeaderColumn {}

.ReactVirtualized__Table__sortableHeaderIcon {}