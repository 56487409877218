.ui.table.products-table {
  display: block;
  overflow-y: auto;

  tr.disabled td {
    color: rgba(40, 40, 40, .3);
  }

  .products-table__title {
    text-align: center;
    font-weight: bold;

    .create-button {
      float: right;
    }
  }

  .products-table__header-link {
    cursor: pointer;
  }
}