.audit {
  display: flex;
  align-items: center;

  &__label {
    margin: 0 0.25rem 0.25rem;
    color: #666;
    font-size: 0.92857143em;
    font-weight: 700;
    text-transform: none;
  }
}