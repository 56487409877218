.collapsible-title {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.ui.accordion .title {
  color: inherit !important;
  padding: 0 !important;
  ;
}