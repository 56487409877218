.availability-form {
  &--tree-container {
    margin: 1em 0;
  }

  &--tree-text {
    line-height: 1.5em;
  }
}
.filtered-out-node {
  display: none;
}