.date {
  width: 200px;
}

.user {
  width: 200px;
}

.user-email {
  padding-left: 8px;
  font-size: 12px;
  color: grey
}

.copy-bttn {
  cursor: pointer
}

.attr-change-container {
  display: flex;
  flex-direction: column;
}

.arrow {
  display: block;
  white-space: nowrap;
}

.attr-change {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: lightgray;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 4px 1rem;
  text-align: left;
}

.from {
  padding: 1rem;
  color: red;
  font-style: italic;
  word-break: break-all;
}

.to {
  padding: 1rem;
  color: green;
  font-style: italic;
  word-break: break-all;
}

.tableContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 8px;
}

.tableHeader {
  display: flex;
  align-items: center;
  background-color: #F7F7F7;
  // box-shadow: 0 3px 8px 0 rgb(211 211 211 / 10%);
  border-bottom: 2px rgb(211 211 211 / 10%);
  z-index: 1;
  .tableHeaderCell {
    padding: 10px 5px;

    &.size-sm {
      min-width: 50px;
    }
    &.size-md {
      min-width: 100px;
    }
    &.size-lg {
      min-width: 230px;
    }
    &.size-auto {
      width: 100%;
    }
  }
}

.tableBody {
  height: 73vh;
  overflow: scroll;
  
  >:nth-child(even) {
    background-color: #F7F7F7;
  }
  .created {
    background-color: #E8FFE6;
  }
  .tableRow {
    display: flex;
    padding: 10px 5px;
    border-top: 1px solid;
    border: 1px solid rgb(230, 230, 230);
    
    
    .tableCell {
      display: table-cell;
      &.size-sm {
        min-width: 50px;
      }
      &.size-md {
        min-width: 100px;
      }
      &.size-lg {
        min-width: 230px;
      }
      &.size-auto {
        width: 100%;
      }
    }
  }

  .auditLoadMore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
  }

  .itemsLoadingContainer {
    padding: 15px;
  }

}

.auditRecordPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.locationChangesList {
  list-style: none;
  li {
    margin-bottom: 5px;
  }

  .tncAttrsList {
    list-style: none;
    li {
      white-space: nowrap;
      overflow-x: scroll;
      width: 380px;
      padding-right: 5px;
    }
    .attrLabel {
      font-weight: bold;
    }
  }
}
