.location-price-modal {
  &--form {
    width: 100%;

    .ui.input,
    .ui.dropdown {
      width: 100%;
      margin: 0 .25rem .25rem 0;
    }
  }
}

.cannot-edit-text {
  margin-bottom: 16px;
}
