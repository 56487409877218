.group-form__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-form__child-group-header {
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: start;

  .ui.header:last-child {
    margin-bottom: 16px;
  }
}