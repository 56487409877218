.dividerRow {
  font-weight: bold;
}

.selectAll {
  text-transform: none;
  margin-bottom: 4px;
}

.activeButton {
  cursor: pointer !important;
}