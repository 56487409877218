.location-tnc-modal {
  &--modal-content {
    text-align: center;
  }

  &--form-wrapper {
    display: flex;
    justify-content: space-between;

    &--items {
      margin: .1rem;
    }
  }

  &--buttons-wrapper {
    display: flex;
    justify-content: end;

    &--items {
      margin: .1rem;
    }
  }

  &--form {
    .ui.input.field, .ui.dropdown.field {
      margin: 0 .25rem .25rem 0;
    }
  }

  &--table {
      overflow-wrap: anywhere;
      max-width: 100%;
  }

}