.alchemist-fade-in {
  animation: fade-in 0.15s ease-in-out forwards;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.alchemist-fade-out {
  animation: fade-out 0.2s ease-in-out forwards;

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

.alchemist-container {
  padding-bottom: 100px;
}
