.form-actions-drawer {
  &--container {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1em 0;
    background-color: white;
    border-top: 1px solid #b0b0b0;
  }
}
